import { Controller } from "@hotwired/stimulus"

const maxControlsOnPage = 10;

export default class CarouselController extends Controller {
  static targets = [ 'main', 'wrapper', 'control' ]

  initialize() {
    this.currentIndex = 0
    this.touchStartX = 0
    this.touchEndX = 0
    this.carouselCount = parseInt(this.mainTarget.dataset.carouselCount)
    this.pages = Math.ceil(this.carouselCount/maxControlsOnPage)
  }

  connect() {
    this.mainTarget.addEventListener('touchstart', this.handleTouchStart.bind(this), false);
    this.mainTarget.addEventListener('touchend', this.handleTouchEnd.bind(this), false);
  }

  disconnect() {
    this.mainTarget.removeEventListener('touchstart', this.handleTouchStart.bind(this), false);
    this.mainTarget.removeEventListener('touchend', this.handleTouchEnd.bind(this), false);
  }

  controlClick(event) {
    const imgIndex = parseInt(event.target.dataset.index)
    this.goToIndex(imgIndex)
  }

  swipeDirection(direction) {
    if (direction === 'left' && this.currentIndex < this.carouselCount - 1) {
      this.goToIndex(this.currentIndex + 1);
    } else if (direction === 'right' && this.currentIndex > 0) {
      this.goToIndex(this.currentIndex - 1);
    }
  }

  handleTouchStart(event) {
    this.touchStartX = event.touches[0].clientX;
  }

  handleTouchEnd(event) {
    this.touchEndX = event.changedTouches[0].clientX;
    const deltaX = this.touchEndX - this.touchStartX;
    if (deltaX > 50) {
      this.swipeDirection('right');
    } else if (deltaX < -50) {
      this.swipeDirection('left');
    }
  }

  goToIndex(index) {
    this.currentIndex = index;
    const offset = -index * this.wrapperTarget.clientWidth;
    this.wrapperTarget.style.transform = `translateX(${offset}px)`;
    this.updateControls();
  }

  updateControls() {
    this.controlTargets.forEach((dot, index) => {
      if (index === this.currentIndex) {
        dot.classList.add('active');
      } else {
        dot.classList.remove('active');
      }
    });
  }
}
